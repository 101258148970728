import React from "react"
import PropTypes from "prop-types"
import Box from "./box"

const Hyphen = ({ color, space }) => {
  return (
    <Box
      as="span"
      sx={{
        fontSize: "inherit",
        fontFamily: "inherit",
        color: color,
        mx: space,
      }}
    >
      -
    </Box>
  )
}

Hyphen.propTypes = {
  color: PropTypes.string,
  space: PropTypes.number,
}

Hyphen.defaultProps = {
  color: "whisper",
  space: 0,
}

export default Hyphen
